import axios from 'axios'

//获取链接是否已被评价（邮件评价跳转页面用）
export function caseEvacuatedOrNot(data) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/ServiceEvaluation/caseEvacuatedOrNot`, data)
}
//邮箱点击评价链接后，查询评价设置和评价详情
export function getEvaluationSettingWithoutlogin(data) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/ServiceEvaluation/getEvaluationSettingWithoutlogin`, data)
}
//保存服务评价（邮件）
export function SaveServiceEvaluationWithoutlogin(data) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/ServiceEvaluation/SaveServiceEvaluationWithoutlogin`, data)
}