<template>
  <div class="mainBox" v-loading="evLoading">
    <div class="backColor" v-show="isshowContent">
      <div class="eavlutionBox" v-show="!isEvaluate">
        <!-- 评价中 -->
        <div class="evluationIng" v-show="isEvEnter">
          <div class="evaText">{{ evaText }}</div>
          <div class="mainContent">
            <!-- 评价表情 -->
            <div class="evaType">
              <div
                class="evaTypeBox"
                :class="{ evActive: index === evIndex }"
                v-for="(item, index) in evaTypeData"
                :key="index"
                @click="chooseType(index)"
              >
                <!-- 表情类型 -->
                <img :src="item.img" alt="" />
                <!-- 评价类型 -->
                <div class="eavlutiontype">{{ item.text }}</div>
              </div>
            </div>

            <!-- 评价内容 -->
            <!-- 好评 -->
            <div class="evaContent" v-show="evIndex === 0">
              <div class="evaContentText">
                {{ evaTextGood }}
              </div>
              <el-input
                type="textarea"
                :rows="5"
                placeholder=""
                v-model="textareaGood"
              >
              </el-input>
            </div>
            <!-- 一般 -->
            <div class="evaContent" v-show="evIndex === 1">
              <div class="evaContentText">
                {{ evaTextCenter }}
              </div>
              <el-input
                type="textarea"
                :rows="5"
                placeholder=""
                v-model="textareaCenter"
              >
              </el-input>
            </div>
            <!-- 差评 -->
            <div class="evaContent" v-show="evIndex === 2">
              <div class="evaContentText">{{ evaTextBad }}</div>
              <el-input
                type="textarea"
                :rows="5"
                placeholder=""
                v-model="textareaNo"
              >
              </el-input>
            </div>
          </div>
          <!-- 提交评价 -->
          <div class="submitBox" @click="submitMethod">确定</div>
        </div>
        <!-- 评价完成 -->
        <div class="evEnter" v-show="!isEvEnter">
          <div class="cloudccImg">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-cloudcc_orange"></use>
            </svg>
          </div>
          <div class="evaTextEnter">{{ evaTextEnter }}</div>
          <div class="evHuiFu">
            <!-- 表情类型 -->
            <img :src="evEnterImg" alt="" />
            <!-- 评价后的回复 -->
            <div class="evHuiFuTest">{{ evEnterTest }}</div>
          </div>
        </div>
      </div>
      <!-- 已经评价过 -->
      <div class="evAlready" v-show="isEvaluate">
        <span
          >Thank you for your enthusiasm, but this case has already been
          rated</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import * as evaluationApi from "./api.js";
export default {
  name: "mailEvaluation",
  data() {
    return {
      evLoading: true,
      jwtText: window.location.hash.split("jwt=")[1],
      isEvaluate: false, // 是否评价过  false为没有评价过  展示评价页面
      evIndex: 0,
      evaText: "请您进行评价",
      evaTextEnter: "感谢您的评价",
      evaTextGood: "", //好评 返回的评语
      evaTextCenter: "", //中评 返回的评语
      evaTextBad: "", //差评 返回的评语
      evaTypeData: [], //评价详情数据
      textareaGood: "", //好评文本
      textareaCenter: "", //一般文本
      textareaNo: "", //差评文本
      textareaContent: "", //用户评价的文本
      evNumver: 0, //用户评价的分数  好评10 一般5 差评1
      isEvEnter: true,
      evEnterImg: "", //用户评价完  对应的好中差评 表情
      evEnterTest: "", //用户评价完 回复用户的话
      isshowContent: true, //初始页面 获取链接是否已被评价 接口返回404的话 展示空白页(评价链接有改动)
    };
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    // 初始页面 获取链接是否已被评价
    this.caseEvacuatedOrNot();
    // 评价页面展示
    this.evaDetail();
  },
  methods: {
    // 初始页面 获取链接是否已被评价
    async caseEvacuatedOrNot() {
      this.evLoading = true;
      let params = {
        jwt: this.jwtText,
      };
      let res = await evaluationApi.caseEvacuatedOrNot(params);
      if (res.data.data.result === "false") {
        // 没有评价过  可以进行评价
        this.isEvaluate = false;
      } else if (res.data.data.result === "true") {
        this.isEvaluate = true;
      } else {
        this.isshowContent = false;
      }
      this.evLoading = false;
    },
    // 评价页面展示
    async evaDetail() {
      let params = {
        jwt: this.jwtText,
      };
      let res = await evaluationApi.getEvaluationSettingWithoutlogin(params);
      this.evaText = res.data.data.evaluationquestion;
      this.evaTypeData = [
        {
          img: res.data.data.emailemojimap.good,
          text: res.data.data.detail[0].evaluations,
        },
        {
          img: res.data.data.emailemojimap.commonly,
          text: res.data.data.detail[1].evaluations,
        },
        {
          img: res.data.data.emailemojimap.bad,
          text: res.data.data.detail[2].evaluations,
        },
      ];
      // 好评 返回的评语
      this.evaTextGood = res.data.data.detail[0].reply;
      // 中评 返回的评语
      this.evaTextCenter = res.data.data.detail[1].reply;
      // 差评 返回的评语
      this.evaTextBad = res.data.data.detail[2].reply;
    },

    // 选择评价类型
    chooseType(index) {
      this.evIndex = index;
    },
    // 确认提交评价
    async submitMethod() {
      this.isEvEnter = false;
      if (this.evIndex === 0) {
        this.textareaContent = this.textareaGood;
        this.evNumver = 10;
        this.evEnterImg = this.evaTypeData[0].img;
        // this.evEnterTest = this.evaTextGood
      } else if (this.evIndex === 1) {
        this.textareaContent = this.textareaCenter;
        this.evNumver = 5;
        this.evEnterImg = this.evaTypeData[1].img;
        //  this.evEnterTest = this.evaTextCenter
      } else {
        this.textareaContent = this.textareaNo;
        this.evNumver = 1;
        this.evEnterImg = this.evaTypeData[2].img;
        //  this.evEnterTest = this.evaTextBad
      }
      let params = {
        jwt: this.jwtText,
        source: "邮件",
        aggregatescore: this.evNumver,
        evaluate: this.textareaContent,
      };
      let res = await evaluationApi.SaveServiceEvaluationWithoutlogin(params);
      this.evEnterTest = res.data.data.reply;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainBox {
  width: 100%;
  padding-top: 80px;
  box-sizing: border-box;
  // height: 100%;
  .backColor {
    width: 90%;
    padding: 80px 60px 80px;
    background: #f3f3f3;
    margin: 0 auto;
    // 已经评价过
    .evAlready {
      width: 100%;
      // margin: 0 auto;
      font-size: 18px;
      border: solid 1px #dedcda;
      text-align: center;
      background: white;
      padding: 130px 0 130px 0;
    }
    .eavlutionBox {
      width: 86%;
      margin: 0 auto;
      border: solid 1px #dedcda;
      background: white;
      // 评价中
      .evluationIng {
        padding: 30px 0;
        position: relative;
        .evaText {
          text-align: center;
          margin: 30px auto;
        }
        //  具体内容
        .mainContent {
          width: 60%;
          margin: 0 auto;
          //  评价表情
          .evaType {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .evaTypeBox {
              width: 30%;
              padding: 20px 0;
              border: solid 1px #dedcda;
              border-radius: 5px;
              text-align: center;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 40px;
                height: 40px;
              }
              .eavlutiontype {
                margin-top: 10px;
              }
            }
          }
          //  评价文本
          .evaContent {
            margin: 50px auto;
            .evaContentText {
              text-align: center;
              margin-bottom: 15px;
            }
          }
          // 选中评价类型 样式
          .evActive {
            background: #f3f3f3;
          }
        }
        //   提交
        .submitBox {
          position: absolute;
          bottom: 3%;
          left: 47%;
          width: 60px;
          height: 30px;
          line-height: 29px;
          text-align: center;
          border-radius: 5px;
          background: #006dcc;
          color: #ffffff;
          font-size: 12px;
          margin: 0 auto;
          cursor: pointer;
        }
      }
      // 评价完成
      .evEnter {
        position: relative;
        width: 100%;
        text-align: center;
        .cloudccImg {
          width: 92px;
          height: 38px;
          position: absolute;
          background-color: white;
          top: -60px;
          left: 46%;
          .icon {
            width: 71px;
            height: 38px;
            display: inline-block;
          }
        }
        .evaTextEnter {
          padding: 15px 0;
          font-size: 14px;
          color: #333333;
          background: #fcfcfc;
        }
        .evHuiFu {
          padding: 20px 0 100px 0;
          img {
            display: inline-block;
            width: 36px;
            height: 36px;
          }
          .evHuiFuTest {
            font-size: 12px;
            color: #333333;
            margin: 20px 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .mainBox {
    width: 400px;
    font-size: 12px;
    margin: 0 auto;
    .backColor {
      width: 365px;
      padding: 30px 20px 30px;
      .evAlready {
        width: 327px;
        font-size: 14px;
        padding: 60px 20px;
      }
      .eavlutionBox {
        width: 313px;
        .evluationIng {
          // width: 100px;
          .mainContent {
            width: 250px;
            .evaTypeBox {
              img {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
        .evEnter {
          .cloudccImg {
            top: -70px;
            left: 34%;
          }
        }
      }
    }
  }
}
</style>